@charset "utf-8";
/*------------------------------------------------------------------
[Master Stylesheet]

Project:    "Ninja Truck - Online truck booking"
Version:    1.0
Created:    "30-03-2020"
Use:   "Admin panel"
-------------------------------------------------------------------*/


/*------------------------------------------------------------------
[Table of contents]

1. Import Fonts & Icnons
2. Colors
3. Page loader / .page-loader
4. Global styles
5. Buttons and CTA
6. Forms fields
7. Animations

-------------------------------------------------------------------*/

/*------------------------------------------------------------------

Import Fonts & Icnons

-------------------------------------------------------------------*/
@import url('https://fonts.googleapis.com/css?family=Nunito:400,600,700,800,900&display=swap');


/*------------------------------------------------------------------

Colors

-------------------------------------------------------------------*/




/*------------------------------------------------------------------

Page loader / .page-loader

-------------------------------------------------------------------*/
.e-api-loader{
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-image: url("../images/lazyloader-2.gif");
	width: 140px;
	height: 24px;
	display: inline-block;
}
.e-lazy-loader{
  display: inline-block;
  width: 140px;
  height: 140px;
  background-size: contain;
  background-image: url("../images/lazyloader-2.gif");
}


/*------------------------------------------------------------------

Global styles

-------------------------------------------------------------------*/
*, *:focus{
	outline: none !important;
  border: none;
}
body{
	font-family: Nunito;
	font-style: normal;
	overflow: hidden;
}
h2{
	font-weight: 600;
	font-size: 18px;
	color: #192A3E;
}
h3{
  font-weight: 600;
  font-size: 16px;
  color: #000;
}
section{
	background: #F5F6F8;
}
header{
	padding: 20px;
	background: #FFFFFF;
	z-index: -1;
}
.e-main-content{
	padding: 30px;
	overflow-y: scroll;
	height: 90vh;
	position: initial;
  
}
a{
	color: #000;
}
a:hover{
  color: #FF4A31;
}
.e-link{
  font-size: 14px;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}
.e-link:hover{
  color: #FF4A31;
}
.text-danger, .text-success{
	font-size: 13px;
}
.e-s-link-txt{
	font-size: 14px;
	cursor: pointer;
}
.e-s-link-txt:hover{
	color: #002A5C;
}

/*------------------------------------------------------------------

Table

-------------------------------------------------------------------*/
.table{
	background: #fff;
	border-radius: 5px;
	box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
}
.table thead th{
	font-weight: 600;
	font-size: 13px;
	color: #334D6E;
	opacity: 0.5;
	border-top: 0;
	padding: 15px;
	border-bottom: 1px solid #EBEFF2;
}
.table tbody td{
	border-bottom: 1px solid #EBEFF2;
	font-size: 14px;
}
.table tbody td.e-min-width{
	min-width: 130px;
}
.table tbody td span.e-s-txt{
	font-size: 12px;
}
.e-clickable-row:hover{
	background: #f9f9f9;
	cursor: pointer;
}
.table a{
  font-size: 14px;
  color: #007bff;
  text-decoration: underline;
}
.table a:hover{
  color: #FF4A31;
}
/*------------------------------------------------------------------

Nothing Found

-------------------------------------------------------------------*/
.e-no-result-wrap{
  height: 70vh;
}
.e-no-result-wrap img{
  width: 200px;
  margin-bottom: 40px;
}

/*------------------------------------------------------------------

Scroll bar

-------------------------------------------------------------------*/
::-webkit-scrollbar{width: 0px;}
::-webkit-scrollbar-track{background: #ccc;}
::-webkit-scrollbar-thumb{background: #2e42b6;}
::-webkit-scrollbar-thumb:hover{background: #555;}


/*------------------------------------------------------------------

Modals

-------------------------------------------------------------------*/
.modal{
  z-index: 9999;
}
.modal-body p{
	font-size: 13px;
	line-height: 18px;
	letter-spacing: 0.01em;
	color: #4C5862;
}
.modal-body h6{
  font-size: 14px;
  font-weight: 600;
}
.modal-header h5{
	font-weight: 600;
	font-size: 15px;
	line-height: 20px;
	letter-spacing: 0.01em;
	color: #323C47;
}
.modal-header{
	padding: 15px 15px 0px 15px;
	border-bottom: 0;
}
.modal-header button{
	box-shadow: none;
	font-size: 18px !important;
	font-weight: 100 !important;
}
.modal-footer{
	padding: 0px 15px 15px 15px;
	border-top: none;
}
.modal-open .modal{
  background: rgba(255, 255, 255, 0.9);
}
.modal.show .modal-content{
	background: #FFFFFF;
  box-shadow: 0px 0px 16px rgba(117, 117, 117, 0.1);
	border-radius: 4px;
	border: none !important;
}
.e-modal-s-txt{
	display: block;
	padding: 10px 15px 0;
	font-size: 13px;
	line-height: 18px;
	letter-spacing: 0.01em;
	color: #4C5862;
	font-size: 12px;
	line-height: 14px;
}


/*------------------------------------------------------------------

ellipsis

-------------------------------------------------------------------*/
.e-p-ellipsis-2{
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  height: 35px;
  line-height: 30px;
}
.e-p-ellipsis-1{
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  height: 20px;
  line-height: 18px;
}
.e-sidebar-email{
	width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

/*------------------------------------------------------------------

Icons

-------------------------------------------------------------------*/
.e-close-icon{
  background-size: cover;
  background-position: center;
  background-image: url("../images/close.svg");
  display: inline-block;
  width: 18px;
  height: 18px;
  cursor: pointer;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-close-icon:hover{
  background-image: url("../images/close-active.svg");
}

/*------------------------------------------------------------------

Forms fields

-------------------------------------------------------------------*/
input, textarea{
	box-shadow: none;
	border: none;
	width: 100%;
	border-bottom: 1px solid #D3D8DD;
	padding-top: 25px;
	font-size: 14px;

	-webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-input-wrap{
	position: relative;
	display: block;
	margin-bottom: 30px;
}
input ~ label, textarea ~ label{
	position: absolute;
	left: 0px;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.01em;
	color: #818E9B;

	-webkit-transition: all .3s ease;
  transition: all .3s ease;
}
input:focus, textarea:focus{
	border-bottom: 1px solid #1B61B6;
}
input:focus ~ label, textarea:focus ~ label{
	color: #1B61B6;
}
button{
	width: initial;
	padding: 10px 20px;
	background: #FF4A31;
	box-shadow: 0px 4px 10px #D7E0EA;
	border-radius: 8px;
	color: #FFFFFF;
  font-weight: 700;
  font-size: 14px;

	-webkit-transition: .3s linear;
  transition: .3s linear;
}
button:hover{
	-webkit-transform: scale(.92);
  transform: scale(.92);
  box-shadow: none;
}
button.e-inactive{
	opacity: .5;
	cursor: not-allowed !important;
}
button.e-inactive:hover{
	-webkit-transform: scale(1);
  transform: scale(1);
  box-shadow: 0px 4px 10px #D7E0EA;
}
button.e-s-btn{
  background: #002A5C;
  border-radius: 5px;
  font-size: 14px;
}
button.e-s-btn-2{
  background-color: transparent;
  border: 1px solid #002A5C;
  border-radius: 5px;
  font-size: 14px;
  color: #002A5C;
  padding: 6px 12px;
  font-size: 13px;
}
button.e-s-btn-2.e-btn-reverse{
  background-color: transparent;
  border: 1px solid #484848;
  border-radius: 5px;
  font-size: 14px;
  color: #484848;
  padding: 6px 12px;
  font-size: 13px;
}
button.e-p-btn.e-loading{
  min-width: 140px;
  min-height: 35px;
  background-image: url("../images/loader/loading.gif");
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: center;
}
.e-upload-btn{
  background-size: 14px;
  background-position: center 15px ;
  background-image: url("../images/upload.svg");
  background-repeat: no-repeat;
  font-size: 12px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 35px 15px 15px;
  display: inline-block;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  cursor: pointer;
}
.e-upload-btn:hover{
  color: #FF4A31;
  background-image: url("../images/upload-active.svg");
  border: 1px solid #FF4A31;
  background-color: #f9f9f9;
}
.e-nav-button{
  position: absolute;
  top: 15px;
  right: 120px;
  padding: 6px 12px;
  font-size: 13px;
  font-weight: 600;
}
.e-t-btn {
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  text-align: left;
  background: #FFFFFF;
  border: 1px dashed #FF4A31;
  color: #FF4A31;
  border-radius: 2px;
  width: 48%;
  box-shadow: none;
  padding: 10px 10px;
}

/*------------------------------------------------------------------

Select box

-------------------------------------------------------------------*/
.e-filter-label{
	font-size: 12px;
}
.css-26l3qy-menu{
	min-width: 120px !important;
	font-size: 12px !important;
	box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
	background: #FFFFFF;
	border: none !important;
}
.css-yk16xz-control, .css-1pahdxg-control{
	font-size: 12px;
	box-shadow: none !important;
	max-width: 200px;
}

/*------------------------------------------------------------------

Search Input

-------------------------------------------------------------------*/
.e-search-input{
	background: transparent;
	width: 180px;
	position: absolute;
	right: 15px;
	bottom: 10px;
	border-bottom: 0;
	padding-bottom: 6px;
	letter-spacing: 0.01em;
	background-position: 0 29px;
	background-repeat: no-repeat;
	background-size: 14px;
	padding-left: 25px;
  background-image: url("../images/search-icon.svg");
  font-size: 14px;
}
.e-search-input:focus, .e-search-input:active, .e-search-input:valid{
	width: 280px;
	border-bottom: 1px solid #1B61B6;
	background-image: url("../images/search-icon-blue.svg");
}
.e-search-input::-webkit-input-placeholder {
  font-weight: 600;
	font-size: 12px;
	color: #90A0B7;
}
.e-search-input:-ms-input-placeholder {
  font-weight: 600;
	font-size: 12px;
	color: #90A0B7;
}
.e-search-input::placeholder {
	font-weight: 600;
	font-size: 12px;
	color: #90A0B7;
}


/*------------------------------------------------------------------

Search Input

-------------------------------------------------------------------*/
.e-checkbox-container{
	display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.e-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
}
.e-checkbox-container:hover input ~ .checkmark {
  background-color: #ccc;
}
.e-checkbox-container input:checked ~ .checkmark {
  background-color: #2196F3;
}
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.e-checkbox-container input:checked ~ .checkmark:after {
  display: block;
}
.e-checkbox-container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
/*------------------------------------------------------------------

Drop Down

-------------------------------------------------------------------*/
.e-dropdown{
	border-radius: 5px;
  box-shadow: 3px 4px 6px 1px rgba(0, 0, 0, 0.06);
  border: 1px solid #f1f1f1;
  padding: 12px 15px;
  background: #f9f9f9;
  display: inline-block;
  cursor: pointer;
  z-index: 2;

  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-dropdown-item{
	font-size: 14px;
}
.e-dropdown-item:hover{
	color: #002A5C;
	text-decoration: underline;
}
.e-dropdown-overlay{
	background: rgba(255, 255, 255, 0.7);
	position: fixed;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	display: inline-block;
	z-index: 1;
}

/*------------------------------------------------------------------

Tab

-------------------------------------------------------------------*/
.e-tab-head{
	font-size: 20px;
	font-weight: 600;
	margin-right: 30px;
	color: #ccc;
	cursor: pointer;

	-webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-tab-head:hover{
	color: #000;
}
.e-tab-head.e-active{
	color: #000;
	text-decoration: underline;
}

/*------------------------------------------------------------------

Charts

-------------------------------------------------------------------*/
.apexcharts-legend .apexcharts-legend-marker[rel = "1"]{
	background: #002A5C !important;
}
.apexcharts-legend .apexcharts-legend-marker[rel = "2"]{
	background: #FFB606 !important;
}
.apexcharts-inner{
	bottom: 0px !important;
	position: relative !important;
	top: initial !important;
}
.apexcharts-toolbar{
	display: none !important;
}



/*------------------------------------------------------------------

Radio button

-------------------------------------------------------------------*/
[type="radio"]:checked,
[type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label{
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  margin-right: 30px;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.01em;
  color: #818E9B;
  transition: all .3s ease;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
  content: '';
  width: 10px;
  height: 10px;
  background: #FFB606;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
[type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*------------------------------------------------------------------

Datepicker

-------------------------------------------------------------------*/
.e-datepicker-wrap{
  border-bottom: 1px solid #D3D8DD;
}
.e-datepicker-wrap .e-date-label{
	left: 0px;
	font-weight: 600;
	font-size: 12px;
	letter-spacing: 0.01em;
	color: #818E9B;
	transition: all .3s ease;
	display: block;
}
.DateRangePickerInput__withBorder{
  border: 1px solid #dbdbdb;
  padding: 3px 15px;
  border-radius: 5px !important;
}
.DateInput_input{
	font-size: 12px !important;
	padding-left: 0 !important;
}
.DateRangePickerInput_arrow{
  margin-right: 15px;
}
.DateRangePickerInput_arrow_svg{
	margin-right: 20px;
	height: 16px;
	width: 20px;
}
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover{
	background: #002A5C !important;
	border: 1px double #002A5C !important;
}
.CalendarDay__selected_span{
	background: rgba(0, 42, 92, 0.74) !important;
	border: 1px double rgba(0, 42, 92, 0.74) !important;
}
.CalendarDay__default:hover{
	background: rgba(0, 42, 92, 0.74) !important;
	border: 1px double rgba(0, 42, 92, 0.74) !important;
}
.DateRangePickerInput{
  width: 100%;
}


/*------------------------------------------------------------------

Toast

-------------------------------------------------------------------*/
.Toastify__toast{
  padding: 15px !important;
}
.Toastify__close-button{
  display: none !important;
}
.Toastify__toast-body{
  padding: 10px 40px !important;
  background-image: url("../images/exclamation-mark.svg");
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: left;
}
.Toastify__toast--default{
  color: #232323 !important;
}




/*------------------------------------------------------------------

Animations & Transitions

-------------------------------------------------------------------*/
.e-card-transition{
  cursor: pointer;
  -webkit-transition: all .5s ease;
  transition: all .5s ease;
}
.e-card-transition:hover{
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}




