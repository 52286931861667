@charset "utf-8";

/*------------------------------------------------------------------
Primary Yellow: #FFB606;
Secondary Blue: #002A5C;

-------------------------------------------------------------------*/








/* -------------------------- Global components and pages -------------------------- */

/*-------Scrollbar-------*/
*{
  scrollbar-width: thin !important;
  scrollbar-color:  #FF5F58 #FF5F5820;
}
*::-webkit-scrollbar{
  display: block!important;
  width: 9px !important ;
  height: 9px!important;
  background-color: #FF5F5820;
  
}
*::-webkit-scrollbar-thumb{
  border-radius: 12px!important ;
  background: #FF5F58  ;
}

/*------------------------------------------------------------------

Login

-------------------------------------------------------------------*/
.e-login-logo-wrap{
	background: #FFFFFF;
	padding: 15px;
}
.e-login-logo-wrap img{
	max-width: 170px;

}
.e-login-section{
  background: linear-gradient(90deg, rgba(108, 27, 182, 0.1) 0%, rgba(57, 112, 175, 0.1) 100%);
	height: 100vh;
}
.e-login-wrap{
	background: #FFFFFF;
	box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
	border-radius: 3px;
	padding: 30px;
}
.e-login-img img{
  position: absolute;
  left: -150px;
}
/*------------------------------------------------------------------

Sidebar

-------------------------------------------------------------------*/
.e-logout{
  color: #334D6E;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  padding-left: 20px;
  background-image: url("../images/logout.svg");
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: 12px;
  cursor: pointer;
  padding-right: 15px;
}
.e-sidebar-wrap{
  height: 100vh;
  background: #FFFFFF;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
  z-index: 1;
}
.e-sidebar-list-wrap{
  max-height: calc(100vh - 78px);
  overflow-y: auto;
}
.e-sidebar-logo{
  border-bottom: 1px solid #EBEFF2;
  padding: 14px 0;
}
.e-sidebar-logo img{
  max-width: 180px;
}
.e-sidebar-profile-wrap img{
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.e-sidebar-profile-wrap h5{
  font-weight: 600;
  font-size: 14px;
  color: #192A3E;
}
.e-sidebar-profile-wrap .e-email{
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.01em;
  color: #90A0B7;
}
.e-sidebar-item a{
  padding-left: 30px;
  letter-spacing: 0.01em;
  background-position: 0 3px;
  background-repeat: no-repeat;
  background-size: 22px;
  font-weight: 600;
  font-size: 14px;
  color: #737373;
  display: block;

  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-sidebar-item a:hover, .e-sidebar-item a.e-active{
  color: #FF4A31;
  text-decoration: underline;
}
.e-sidebar-home{
  background-size: 18px !important;
  background-position: 0 0px !important;
  background-image: url("../images/sidebar/home.svg");
}
.e-sidebar-home:hover, .e-sidebar-home.e-active{
  background-image: url("../images/sidebar/home-active.svg");
}
.e-sidebar-layouts{
  background-image: url("../images/sidebar/layouts.svg");
  background-size: 18px !important;
  background-position: 0 0px !important;
}
.e-sidebar-layouts:hover, .e-sidebar-layouts.e-active{
  background-image: url("../images/sidebar/layouts-active.svg");
}
.e-sidebar-users{
  background-image: url("../images/sidebar/user.svg");
  background-size: 20px !important;
  background-position: 0 0px !important;
}
.e-sidebar-users:hover, .e-sidebar-users.e-active{
  background-image: url("../images/sidebar/user-active.svg");
}
.e-sidebar-vendors{
  background-image: url("../images/sidebar/vendor.svg");
  background-size: 18px !important;
  background-position: 0 0px !important;
}
.e-sidebar-vendors:hover, .e-sidebar-vendors.e-active{
  background-image: url("../images/sidebar/vendor-active.svg");
}
.e-sidebar-quotation{
  background-image: url("../images/sidebar/quotation.png");
  background-size: 18px !important;
  background-position: 0 0px !important;
}
.e-sidebar-quotation:hover, .e-sidebar-quotation.e-active{
  background-image: url("../images/sidebar/quotation-active.png");
}
.e-sidebar-notify{
  background-image: url("../images/notification-icon.svg");
  background-size: 18px !important;
  background-position: 0 0px !important;
}
.e-sidebar-notify:hover, .e-sidebar-notify.e-active{
  background-image: url("../images/notification-icon-blue.svg");
}
.e-sidebar-payments{
  background-image: url("../images/sidebar/category.svg");
  background-size: 20px !important;
  background-position: 0 0px !important;
}
.e-sidebar-payments:hover, .e-sidebar-payments.e-active{
  background-image: url("../images/sidebar/category-active.svg");
}
.e-sidebar-plan{
  background-image: url("../images/sidebar/plan.svg");
  background-size: 20px !important;
  background-position: 0 0px !important;
}
.e-sidebar-plan:hover, .e-sidebar-plan.e-active{
  background-image: url("../images/sidebar/plan-active.svg");
}
.e-sidebar-fee{
  background-image: url("../images/sidebar/fee.svg");
  background-size: 18px !important;
  background-position: 0 0px !important;
}
.e-sidebar-fee:hover, .e-sidebar-fee.e-active{
  background-image: url("../images/sidebar/fee-active.svg");
}
.e-sidebar-fee{
  background-image: url("../images/sidebar/fee.svg");
  background-size: 18px !important;
  background-position: 0 0px !important;
}
.e-sidebar-fee:hover, .e-sidebar-fee.e-active{
  background-image: url("../images/sidebar/fee-active.svg");
}
.e-sidebar-payouts{
  background-image: url("../images/sidebar/payouts.svg");
  background-size: 18px !important;
  background-position: 0 0px !important;
}
.e-sidebar-payouts:hover, .e-sidebar-payouts.e-active{
  background-image: url("../images/sidebar/payouts-active.svg");
}
.e-sidebar-truck{
  background-image: url("../images/sidebar/truck.svg");
  background-size: 18px !important;
  background-position: 0 0px !important;
}
.e-sidebar-truck:hover, .e-sidebar-truck.e-active{
  background-image: url("../images/sidebar/truck-active.svg");
}
.e-sidebar-goods{
  background-image: url("../images/sidebar/goods.svg");
  background-size: 18px !important;
  background-position: 0 0px !important;
}
.e-sidebar-goods:hover, .e-sidebar-goods.e-active{
  background-image: url("../images/sidebar/goods-active.svg");
}
.e-sidebar-notification{
  background-image: url("../images/sidebar/notification.svg");
  background-size: 18px !important;
  background-position: 0 0px !important;
}
.e-sidebar-notification:hover, .e-sidebar-notification.e-active{
  background-image: url("../images/sidebar/notification-active.svg");
}
.e-sidebar-discount{
  background-image: url("../images/sidebar/ads.svg");
  background-size: 18px !important;
  background-position: 0 0px !important;
}
.e-sidebar-discount:hover, .e-sidebar-discount.e-active{
  background-image: url("../images/sidebar/ads-active.svg");
}
.e-sidebar-support{
  background-image: url("../images/sidebar/support.png");
  background-size: 18px !important;
  background-position: 0 0px !important;
}
.e-sidebar-support:hover, .e-sidebar-support.e-active{
  background-image: url("../images/sidebar/support-active.png");
}
.e-sidebar-faq{
  background-image: url("../images/sidebar/ads.svg");
  background-size: 18px !important;
  background-position: 0 0px !important;
}
.e-sidebar-faq:hover, .e-sidebar-faq.e-active{
  background-image: url("../images/sidebar/ads-active.svg");
}
.e-sidebar-membership-plans{
  background-image: url("../images/sidebar/plan.png");
  background-size: 18px !important;
  background-position: 0 0px !important;
}
.e-sidebar-membership-plans:hover, .e-sidebar-membership-plans.e-active{
  background-image: url("../images/sidebar/plan-active.png");
}
.e-sidebar-ad-plans{
  background-image: url("../images/sidebar/plan.png");
  background-size: 18px !important;
  background-position: 0 0px !important;
}
.e-sidebar-ad-plans:hover, .e-sidebar-ad-plans.e-active{
  background-image: url("../images/sidebar/plan-active.png");
}
.e-sidebar-driver-request{
  background-image: url("../images/sidebar/driver-request-active.svg");
  background-size: 18px !important;
  background-position: 0 0px !important;
}
.e-sidebar-driver-request:hover, .e-sidebar-driver-request.e-active{
  background-image: url("../images/sidebar/driver-request.svg");
}
.e-sidebar-career{
  background-image: url("../images/sidebar/career.svg");
  background-size: 18px !important;
  background-position: 0 0px !important;
}
.e-sidebar-career:hover, .e-sidebar-career.e-active{
  background-image: url("../images/sidebar/career-active.svg");
}
.e-sidebar-location-admins{
  background-image: url("../images/sidebar/user.svg");
  background-size: 18px !important;
  background-position: 0 0px !important;
}
.e-sidebar-location-admins:hover, .e-sidebar-location-admins.e-active{
  background-image: url("../images/sidebar/user-active.svg");
}


/*-------------------------------------------------------------------

Dashboard

-------------------------------------------------------------------*/
.e-dash-wrap .e-summry-card-wrap{
  padding: 30px;
  background: #fff;
  border-radius: 5px;
}
.e-dash-wrap .e-summry-card-wrap h6{
  font-weight: 800;
  font-size: 18px;
}
.e-page-head{
  position: absolute;
  z-index: 9999;
  margin-top: -70px;
  display: inline-block;
  text-transform: capitalize;
}








/* -------------------------- online marketplace related pages -------------------------- */



/*-------------------------------------------------------------------

Layouts list

-------------------------------------------------------------------*/
.e-list-card{
  display: block;
  padding: 15px;
  background: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  font-size: 14px;
  text-transform: capitalize;

}
.e-layout-list .e-list-card{
  background-image: url("../images/icons/right-arrow.svg");
  background-repeat: no-repeat;
  background-position: 95% center;
  background-size: 14px;
}
.e-layout-list .e-list-card.e-item-inactive{
  background: #fffd;
  box-shadow: none;
  color: #666;
}
.e-item-inactive .e-unhide-btn{
  color: #FF4A31;
  font-weight: 700;
  cursor: pointer;
  opacity: 1;
  transition: 0.3s ease;
}
.e-item-inactive .e-unhide-btn:hover{
  opacity: 0.8;
}
.e-layout-wrap{
  background: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  padding: 15px;
}
.e-layout-row{
  border: 1px dotted #ccc;
}
.e-ad-row{
  background: #f1f1f1;
}
.e-hide-icon{
  width: 20px;
  float: right;
  margin-right: 45px;
  object-fit: cover;
}
.e-ad-full-placeholder{
  background-image: url("../images/img-placeholder.svg");
  background-size: 40px;
  background-position: center 20px;
  background-repeat: no-repeat;
  padding: 60px 30px 30px;
  text-align: center;
  opacity: .6;
  font-size: 14px;
}
.e-ad-section-full-placeholder{
  background-image: url("../images/img-placeholder.svg");
  background-size: 30px;
  background-position: center 15px;
  background-repeat: no-repeat;
  padding: 40px 30px 30px;
  text-align: center;
  opacity: .6;
  font-size: 14px;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}
.e-edit-section{
  background: rgba(178, 178, 243, 0.2);
  padding: 30px;
}
.e-section-head-placeholder{
  border: 1px dotted #ccc;
  padding: 15px;
  font-size: 13px;
  text-align: center;
  background: #ffff;
  text-decoration: underline;
  cursor: pointer;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-section-head-placeholder:hover{
  color: #FF4A31;
}
.e-edit-section .e-close-icon{
  position: absolute;
  right: 22px;
  top: 8px;
  width: 12px;
  height: 12px;
  opacity: .8;
}
.e-h2-stlye1-wrap{
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}
.e-h2-stlye1-wrap h2{
  display: inline-block;
  margin: 0 30px;
  font-weight: 800;
  font-size: 14px;
  line-height: 40px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.e-demo-heading-continer{
  max-height: 270px;
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 5px 0 10px;
}
.e-demo-heading-wrap{
  cursor: pointer;
}
.e-demo-heading-wrap:hover{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.e-demo-heading-wrap.e-active .e-h2-stlye1-wrap{
  border: 1px solid #FF4A31;
}
.e-color-preview-wrap{
  color: #000;
  font-weight: 500;
}
.e-color-preview{
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.e-color-picker-wrap{
  border-radius: 5px;
  box-shadow: 0px 1.5px 5px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #c4c4c4;
  background-color: #fafafa;
  text-align: center;
  padding: 15px;
  color: #6c6969;
  font-size: 14px;
  font-weight: 500;
  display: block;
}
.e-colore-picker{
  opacity: 0;
  width: 1px;
  height: 1px;
  padding-top: 0;
}
.e-demo-element-wrap{
  border-radius: 5px;
}
.e-demo-four-list, .e-demo-two-list, .e-demo-three-list, .e-demo-six-list{
  border: 1px solid #ccc;
  padding: 15px;
}
.e-demo-four-list img, .e-demo-two-list img, .e-demo-three-list img, .e-demo-six-list img{
  width: 30px;
}
.e-demo-list-wrap:hover{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.e-demo-list-wrap.e-active{
  border: 1px solid #FF4A31;
}
.e-view-style-link{
  font-size: 12px;
  padding-top: 10px;
  display: inline-block;
  text-decoration: underline;
}
#listModal .modal-dialog{
  max-width: 750px;
}
#newListModal .modal-dialog{
  max-width: 750px;
}
.e-subcategory-content{
  border: 1px dotted #ccc;
  padding: 15px;
}
.e-row-input-wrap{
  top: -15px;
  position: absolute;
}
.e-row-input-wrap input{
  display: inline-block;
  width: 100px;
  border: 1px solid #ccc;
  padding: 5px 5px 5px 75px !important;
}
.e-row-input-wrap label{
  position: absolute;
  left: 10px;
  top: 7px;
  font-size: 12px;
  z-index: 1;
  font-weight: 600;
}
.e-section-swap-btn{
  background-image: url("../images/right-arrow.svg");
  width: 33px;
  height: 33px;
  background-repeat: no-repeat;
  background-size: 14px;
  background-position: center;
  background-color: #002A5C;
  display: none;
  position: absolute;
  cursor: pointer;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-section-swap-btn:hover{
  opacity: .7;
}
.e-section-swap-btn.e-active{
  display: inline-block;
}
.e-img-upload-content{
  border: 2px solid #3898F0;
  position: relative;
  border-radius: 8px;
  margin-bottom: 15px;
}
.e-img-upload-content img{
  width: 100%;
  height: 120px;
  object-fit: cover;
}
.e-img-upload-content .e-close{
  background: #3898F0;
  border-radius: 0px 4px;
  background-image: url("../images/icons/close-white.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 12px;
  width: 35px;
  height: 30px;
  position: absolute;
  right: 0px;
  top: 0px;
  cursor: pointer;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
}
.e-img-upload-content .e-close:hover{
  opacity: .6;
}
.e-upload-loader{
  background-image: url("../images/loader/lazyload.gif");
  background-size: 80px;
  background-position: center 6px;
  background-repeat: no-repeat;
  width: 100%;
  height: 70px;
  border: 1px dotted #ccc;
  border-radius: 5px;
  margin-bottom: 15px;
}
.e-image-input{
  width: 1px;
  height: 1px !important;
  opacity: 0;
  line-height: 0;
  padding: 0;
}
.e-ad-full-placeholder .e-upload, .e-cat-banner-placeholder .e-upload{
  right: 25px;
  cursor: pointer;
  z-index: 2;
  background-image: url("../images/upload.svg");
  background-repeat: no-repeat;
  width: 16px;
  height: 16px;
  background-position: center;
  background-size: cover;
  top: 5px;
  position: absolute;
}
.e-image-push-image{
  background-image: url("../images/upload.svg");
  background-repeat: no-repeat;
  padding: 42px 70px;
  border: 1px solid #D3D8DD;
  background-size: 30px;
  background-position: center;
  transition: ease 0.5s;
}
.e-image-push-image:hover{
  cursor: pointer;
  border: 1px solid #1B61B6;
}
.e-cat-banner-placeholder .e-upload{
  right: 10px;
}
.e-ad-full-placeholder .e-upload:hover, .e-cat-banner-placeholder .e-upload:hover{
  background-image: url("../images/upload-active.svg");
}
.e-dummy-ads-modal .modal-dialog{
  max-width: 600px;
}
.e-hero-dummy-img{
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}
.e-hero-dummy-img-wrap .e-close-icon{
  position: absolute;
  right: 0;
  top: 0;
  background-color: #f1f1f1;
  width: 24px;
  height: 24px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 14px;
  z-index: 2;
}
.e-ad-full-placeholder.e-l-banner{
  height: 240px;
}
.e-menu-item-tag{
  color: rgba(72, 72, 72, 0.87);
  font-family: Nunito;
  font-size: 14px;
  background: #F4F4F4;
  border: 1px solid #FF4A31;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 5px 10px;
}
.e-menu-setting-wrap h6 span{
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #FF4A31;
  margin-left: 30px;
  cursor: pointer;
}
.e-menu-setting-wrap h6 span:hover{
  opacity: .6;
}
.e-no-menu-item{
  font-size: 13px;
  opacity: .8;
}
/*-------------------------------------------------------------------

Vendor list

-------------------------------------------------------------------*/
.e-vendor-details-wrap{
  background: #fff;
  padding: 25px;
  border-radius: 5px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.e-vendor-details-wrap img{
  width: 60px;
  height: 60px;
  object-fit: cover;
}
.e-vendor-details-wrap h5{
  font-size: 16px;
  font-weight: 600;
  padding-top: 20px;
}
.e-vendor-details-wrap h6{
  font-size: 10px;
  margin-bottom: 0;
  opacity: .7;
}
.e-vendor-details-wrap p{
  font-size: 14px;
  word-wrap: break-word;
}
.e-vendor-more-details{
  border-top: 1px solid #ececec;
}
.e-vendor-more-details a{
  font-size: 14px;
  color: #007bff;
  text-decoration: underline;
}
.e-vendor-more-details a:hover{
  color: #FF4A31;
}
.e-vendor-tab-link-wrap span{
  opacity: .5;
  display: inline-block;
  margin-right: 30px;
  font-weight: 600;
  font-size: 16px;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  cursor: pointer;
}
.e-vendor-tab-link-wrap span:hover{
  opacity: .8;
}
.e-vendor-tab-link-wrap span.e-active{
  opacity: 1;
  text-decoration: underline;
}
.e-vendor-category-list{
  background: #fff;
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  padding: 15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.e-vendor-category-list h6{
  font-size: 10px;
  margin-bottom: 0;
  opacity: .7;
}
.e-vendor-category-list p{
  font-size: 14px;
  word-wrap: break-word;
}
.e-logo-placeholder{
  display: inline-block;
  width: 60px;
  height: 60px;
  text-align: center;
  padding-top: 20px;
  background-color: #eee;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
}
.e-location-admin .e-logo-placeholder{
  padding-top: unset;
  font-size: 18px;
  min-width: 60px;
  min-height: 60px;
  width: unset;
  height: unset;
}
.e-vendor-logo{
  width: 60px;
  height: 60px;
  object-fit: cover;
}

/*-------------------------------------------------------------------

Invoice detail

-------------------------------------------------------------------*/
.e-detail-invoice-wrap h5{
  font-weight: 600;
}
.e-detail-invoice-wrap h6{
  display: block;
  font-weight: 800;
}
.e-detail-invoice-wrap .modal-dialog{
  max-width: 800px;
}
.e-detail-invoice-wrap h4{
  font-weight: 800;
  font-size: 16px;
  margin-bottom: 20px;
}
.e-price-table-header{
  border-bottom: 1px solid #ccc;
}
.e-price-table-item{
  border-bottom: 1px solid #ccc;
}
.e-price-table-total{
  border-top: 1px solid #ccc;
}
.e-price-table-total h6, .e-price-table-total p{
  font-size: 16px;
}
.e-price-table-fully-paid{
  display: inline-block;
  border: 2px solid #3ba229;
  padding: 8px 15px;
  border-radius: 5px;
  color: #3ba229;
  position: absolute;
  right: 30px;
  bottom: 10px;
  transform: rotate(-20deg);
  font-weight: 800;
}
.e-detail-invoice-wrap .e-s-btn{
  position: absolute;
  left: 30px;
  bottom: 30px;
}
.e-detail-invoice-wrap .close{
  position: absolute;
  right: 15px;
  z-index: 1;
}



/*-------------------------------------------------------------------

Scheduled Ads

-------------------------------------------------------------------*/
.e-scheduled-ads-wrap a{
  font-size: 14px;
  text-decoration: underline;
  color: #007bff;
}
.e-scheduled-ads-wrap a:hover{
  color: #FF4A31;
}
.e-scheduled-ads-slots-wrap{
  border-top: 1px solid #ccc;
}
.e-scheduled-ads-slots-wrap h5{
  font-size: 16px;
  font-weight: 800;
}
.e-scheduled-ads-slots span{
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  padding: 6px 15px;
  margin-right: 5px;
  display: inline-block;
}
.e-view-add-content-text{
  font-size: 12px;
  color: #007bff;
  text-decoration: underline;
  position: absolute;
  bottom: 15px;
  right: 15px;
  cursor: pointer;
}
.e-view-add-content-text:hover{
  color: #FF4A31;
}
.e-ads-content-wrap img{
  width: 100%;
}
.e-ads-content-wrap{
  border: 1px solid #ccc;
  padding: 15px;
}
.e-ad-detail-wrap button{
  position: absolute;
  right: 15px;
  z-index: 1;
}
.e-category-wrap .e-list-card h5{
  font-size: 16px;
  font-weight: 800;
  margin-bottom: 30px;
}
.e-category-wrap .e-list-card h6{
  font-size: 14px;
  font-weight: 600;
}
.e-subcategory-wrap{
  border: 1px solid #f1f1f1;
  padding: 15px;
  position: relative;
}
.e-subcategory-item-wrap span{
  display: inline-block;
  padding: 2px 8px;
  border-radius: 10px;
  border: 1px solid #f1f1f1;
  margin-right: 12px;
  margin-bottom: 10px;
}
.e-subcategory-head{
  border-top: 1px solid #ccc;
  padding-top: 20px;
}
.e-subcategory-head h6{
  font-weight: 800;
}
.e-more, .e-close{
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("../images/more-icon.svg");
  background-repeat: no-repeat;
  background-size: 12px;
  background-position: center;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}
.e-close{
  background-image: url("../images/close.svg");
}
.e-product-tag .e-close{
  top: -5px;
  right: -20px;
  background-color: #f1f1f1;
  background-size: 8px;
  width: 16px;
  height: 16px;
}

.e-overlay-category{
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  top: 0;
  left: 0;
  z-index: 9999;
  display: block;
}
.e-more-dropdown{
  position: absolute;
  padding: 15px;
  width: 100px;
  display: inline-block;
  z-index: 999999;
  background: #fff;
  box-shadow: 0px 3px 8px rgba(181, 175, 175, 0.25);
}
.e-more-dropdown span{
  display: block;
  padding: 5px 0;
  cursor: pointer;
}
.e-more-dropdown span:hover{
  color: #FF4A31;
}
.e-subcategory-wrap .e-more-dropdown{
  position: absolute;
  right: 10px;
  top: 30px;
  width: 150px;
  padding: 10px 15px;
  background: #f7f7f7;
}


/*------------------------------------------------

Category List

-------------------------------------------------*/

/*Four Column card list*/
.e-card-four-column{
  z-index: 1;
}
.e-card-four-column p{
  position: absolute;
  margin-bottom: 0;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  bottom: 0;
  background: #000;
  width: 100%;
  color: #fff;
  padding: 15px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
}
.e-card-four-style-2 p{
  width: auto;
  padding: 13px 20px;
  top: 0;
  bottom: initial;
  border-radius: 2px 0 4px 0;
}
.e-card-four-style-1 .e-card-item-wrap{
  padding-bottom: 30px;
}
.e-card-four-style-3 p{
  bottom: -40px;
}
.e-card-four-style-3 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-4 p{
  bottom: 4px;
  position: relative;
}
.e-card-four-style-5 p{
  border-radius: 30px 0 30px 0;
}
.e-card-four-style-5 .e-card-item-wrap{
  padding-bottom: 40px;
}
.e-card-four-style-6 p{
  border-radius: 30px 30px 0 0;
}
.e-card-four-style-6 .e-card-item-wrap{
  padding-bottom: 40px;
}
.e-card-four-style-7 p{
  background: linear-gradient(-45deg, #f1f1f1 80%,  transparent 50%);
  box-shadow: none;
  margin-bottom: -20px;
}
.e-card-four-style-7 .e-card-item-wrap{
  padding-bottom: 40px;
}
.e-card-four-style-8 p{
  position: relative;
  border-radius: 30px;
  bottom: -15px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  padding: 12px;
  font-weight: 700;
}
.e-card-four-style-8 .e-card-item-wrap{
  padding-bottom: 40px;
}
.e-card-four-style-9 p{
  bottom: -26px;
}
.e-card-four-style-9 .e-card-item-wrap{
  padding-bottom: 60px;
}
.e-card-four-style-10 p{
  box-shadow: none;
  margin-bottom: -20px;
}
.e-card-four-style-10 .e-card-item-wrap{
  padding-bottom: 50px;
}
.e-card-four-style-11 p{
  box-shadow: none;
  margin-bottom: -20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.e-card-four-style-11 .e-card-item-wrap{
  padding-bottom: 50px;
}
.e-card-four-style-12 p{
  border: 2px solid #000000;
  padding: 12px;
  bottom: -15px;
  position: relative;
}
.e-card-four-style-12 .e-card-item-wrap{
  padding-bottom: 30px;
}
.e-card-four-style-13 p{
  bottom: -50px;
  box-shadow: none;
  font-weight: 700;
}
.e-card-four-style-13 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-14 p{
  bottom: 0;
  font-weight: 700;
  border-radius: 30px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  padding: 15px;
}
.e-card-four-style-14 .e-card-item-wrap{
  padding-bottom: 40px;
}
.e-card-four-style-15 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.e-card-four-style-15 .e-card-item-wrap{
  padding-bottom: 30px;
}
.e-card-four-style-16 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 100px 0px;
  bottom: -50px;
}
.e-card-four-style-16 .e-card-item-wrap{
  padding-bottom: 80px;
}
.e-card-four-style-16 img{
  border-radius: 100px 4px;
}

.e-card-four-style-17 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  bottom: -60px;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
.e-card-four-style-17 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-17 .e-card-item-wrap img{
  border-radius: 50%;
  width: 90% !important;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}
.e-card-four-style-17 img:hover{
  -webkit-transform: scale(.97) translateX(-50%) !important;
  -ms-transform: scale(.97) translateX(-50%) !important;
  transform: scale(.97) translateX(-50%) !important;
}

.e-card-four-style-18 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  bottom: -60px;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
.e-card-four-style-18 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-18 .e-card-item-wrap img{
  border-radius: 200px 0px 200px 200px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 78% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-18 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-19 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  bottom: -60px;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
.e-card-four-style-19 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-19 .e-card-item-wrap img{
  border-radius: 24px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 78% !important;
}
.e-card-four-style-19 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-20 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  bottom: -60px;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
.e-card-four-style-20 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-20 .e-card-item-wrap img{
  border-radius: 48px;
}

.e-card-four-style-21 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  bottom: -60px;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
.e-card-four-style-21 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-21 .e-card-item-wrap img{
  border-radius: 48px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 90% !important;
}
.e-card-four-style-21 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-22 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  bottom: -60px;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
.e-card-four-style-22 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-22 .e-card-item-wrap img{
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 76% !important;
}
.e-card-four-style-22 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-23 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  bottom: -60px;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
.e-card-four-style-23 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-23 .e-card-item-wrap img{
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 76% !important;
}
.e-card-four-style-23 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-24 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  bottom: -60px;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
.e-card-four-style-24 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-24 .e-card-item-wrap img{
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 90% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-24 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-25 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 100px 0px;
  bottom: 0;
  left: 74%;
  z-index: 1;
  max-width: 160px;
  transform: translateX(-74%);
}
.e-card-four-style-25 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-25 .e-card-item-wrap img{
  border-radius: 0px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 91% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-25 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-26 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0px;
  bottom: 0;
  z-index: 1;
}
.e-card-four-style-26 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-26 .e-card-item-wrap img{
  border-radius: 0px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 91% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-26 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-27 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 100px 0px;
  bottom: 0;
  right: 0;
  z-index: 1;
  max-width: 160px;
}
.e-card-four-style-27 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-27 .e-card-item-wrap img{
  border-radius: 0px 100px;
}

.e-card-four-style-28 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  bottom: -60px;
  font-weight: 800;
  font-size: 16px;
  line-height: 20px;
}
.e-card-four-style-28 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-28 .e-card-item-wrap img{
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 78% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-28 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-29 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  bottom: -60px;
  z-index: 1;
}
.e-card-four-style-29 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-29 .e-card-item-wrap img{
  border-radius: 0px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 78% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-29 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-30 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  bottom: -60px;
  z-index: 1;
}
.e-card-four-style-30 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-30 .e-card-item-wrap img{
  border-radius: 16px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 78% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-30 .e-svg{
  background-image: url("../images/card-bg/Rectangle\ 386.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 255px;
}
.e-card-four-style-30 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-31 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  bottom: -60px;
  z-index: 1;
  padding-top: 8px;
  padding-bottom: 8px;
}
.e-card-four-style-31 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-31 .e-card-item-wrap img{
  border-radius: 0px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 73% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-31 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-32 p{
  box-shadow:none;
  border-radius: 0;
  bottom: -60px;
  z-index: 1;
}
.e-card-four-style-32 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-32 .e-card-item-wrap img{
  border-radius: 0px;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 66% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-32 img:hover{
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.e-card-four-style-33 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  bottom: -60px;
  z-index: 1;
}
.e-card-four-style-33 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-33 .e-card-item-wrap img{
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 84% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-33 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-34 p{
  box-shadow: none;
  border-radius: 40px;
  bottom: -50px;
  z-index: 1;
}
.e-card-four-style-34 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-34 .e-card-item-wrap img{
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 84% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15))
}
.e-card-four-style-34 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-35 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  bottom: -60px;
  z-index: 1;
}
.e-card-four-style-35 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-35 .e-card-item-wrap img{
  width: 94% !important;
  border: 6px solid #F64A7C;
  box-sizing: border-box;
  box-shadow: 16px 4px 40px rgba(0, 0, 0, 0.15);
  border-radius: 24px;
}

.e-card-four-style-36 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 100px 0px;
  bottom: -50px;
  z-index: 1;
}
.e-card-four-style-36 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-36 .e-card-item-wrap img{
  border-radius: 0;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%) matrix(0.99, -0.13, 0.13, 0.99, 0, 0);
  top: 50%;
  width: 65% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15));
}
.e-card-four-style-36 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) matrix(0.99, -0.13, 0.13, 0.99, 0, 0) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) matrix(0.99, -0.13, 0.13, 0.99, 0, 0) !important;
  transform: scale(.97) translate(-50%, -50%) matrix(0.99, -0.13, 0.13, 0.99, 0, 0) !important;
}

.e-card-four-style-37 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  bottom: -60px;
  z-index: 1;
}
.e-card-four-style-37 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-37 .e-card-item-wrap img{
  border-radius: 0;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 65% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15));
}
.e-card-four-style-37 img:hover{
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.e-card-four-style-38 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 0;
  bottom: -60px;
  z-index: 1;
}
.e-card-four-style-38 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-38 .e-card-item-wrap img{
  border-radius: 0;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 91% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15));
}
.e-card-four-style-38 img:hover{
  -webkit-transform: scale(.97) translate(-50%, -50%) !important;
  -ms-transform: scale(.97) translate(-50%, -50%) !important;
  transform: scale(.97) translate(-50%, -50%) !important;
}

.e-card-four-style-39 p{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 40px;
  bottom: -60px;
  z-index: 1;
}
.e-card-four-style-39 .e-card-item-wrap{
  padding-bottom: 70px;
}
.e-card-four-style-39 .e-card-item-wrap img{
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 94% !important;
  filter: drop-shadow(16px 4px 40px rgba(0, 0, 0, 0.15));
}
.e-card-four-style-39 .e-svg{
  background-image: url("../images/card-bg/Rectangle\ 416.png");
  background-size: contain;
  background-position: center;
  top: 0;
  left: 0;
}
.e-card-four-style-39 img:hover{
  -webkit-transform: translate(-50%, -50%) !important;
  -ms-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
}

.e-section-bg-half{
  position: absolute;
  width: 100%;
  height: 70%;
  left: 0;
  bottom: -20px;
  display: inline-block;
  z-index: -1;
}
.e-collection-wrap .e-section-bg-half{
  bottom: -50px;
}
.e-card-item-wrap img{
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}

/*Six Column card list*/
.e-card-six-style-1 p{
  text-align: center;
  border-left: none !important;
  text-decoration: underline;
  color: #000000;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  padding-top: 10px;
  cursor: pointer;
}
.e-card-six-style-2 img{
  /*border-radius: 100px 4px;*/
  width: 100%;
}
.e-card-six-style-2 p{
  background: #000000;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 100px 0px;
  text-align: center;
  padding: 10px;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  margin-top: 10px;
}
.e-cat-layout-list-wrap{
  border-bottom: 1px solid #ccc;
  margin-bottom: 30px;
  padding-bottom: 15px;
}
.e-cat-layout-list-wrap:last-child{
  border-bottom: 0;
}
button.e-loading.e-page-layout-btn{
  min-width: 100px;
  min-height: 30px;
}
.e-section-head-edit{
  border: 1px dotted #ccc;
  padding: 15px 0 10px;
  position: relative;
}
.e-section-head-edit .e-edit{
  background-image: url("../images/edit.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 14px;
  height: 14px;
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
}
.e-section-head-edit .e-edit:hover{
  background-image: url("../images/edit-active.svg");
}
/*------------------------------------------------

Career

-------------------------------------------------*/
.e-remove{
  width: 16px;
  height: 16px;
  background-image: url("../images/delete.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: inline-block;
  cursor: pointer;
}
.e-remove:hover{
  background-image: url("../images/delete-active.svg");
}
.e-career-header {
   color: #818E9B;
   font-size: 13px;
}
input.e-add-career,textarea.e-add-career {
  /*font-weight: 400;
  font-size: 12px;
  border: 1px solid #E3E3E3;
  background-color: #F9F9F9;
  padding: 10px;
  width: 75%;*/
  padding-top: 0px;
}
/*textarea.e-add-career {
  resize: none;
  height: 44px

}*/
.modal-content.e-career-modal {
  padding: 5px 20px 15px;
}
.e-add-career-heading {
  font-weight: bold;
  font-size: 18px;
}
.e-login-admin{
  opacity: 0.7;
  font-weight: 600;
  cursor: pointer;
}
.e-ofer-cancel:hover, .e-login-admin:hover {
  text-decoration: none;
}

/*---------------Membership plans--------------*/
.e-plan-wrap {
  background: #FFFFFF;
  border-radius: 4px;
}
.e-plan-content {
  /*padding: 15px;*/
  padding: 10px 46px 15px 25px;
  /*position: relative;*/
}
.e-edit{
  position: absolute;
  right: 15px;
  background-image: url("../images/icons/edit.svg");
  width: 18px;
  height: 18px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}
.e-delete{
  position: absolute;
  right: 15px;
  background-image: url("../images/icons/trash.svg");
  width: 18px;
  height: 18px;
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
}
.e-edit:hover, .e-delete:hover{
  opacity: .6;
}
.e-plan-content h1{
  font-size: 36px;
  font-weight: 800;
  line-height: 33px;
  letter-spacing: 0.03em;
  color: #081F32;
}
.e-plan-content h1 span{
  font-size: 20px;
  font-weight: 700;
}
.e-plan-content h1 span .e-symbol{
  font-size: 20px;
  font-weight: 800;
}
.e-plan-content h2{
  font-weight: 700;
  font-size: 26px;
  line-height: 25px;

}
.e-plan-content p {
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.4);
}
.e-plan-content ul {
  padding-left: 12px!important;
  font-weight: 400;
  font-size: 15px;
  line-height: 35px;
  color: rgb(0 0 0 / 77%);
}
.e-plan-content ul {
  list-style: none;
}
.e-plan-content ul li::before {
  display: none;
}
.e-plan-content ul li span{
  content: "\2022";
  font-weight: 800;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
  width: 5px;
  height: 5px;
  display: inline-block;
  border-radius: 100%;
  background: red;
  margin-right: 5px;
  position: relative;
  top: -3px;
}
.e-plan-content.e-plan-one h2, .e-plan-content.e-plan-one ul li::before {
  color: #46D68C;
}
.e-plan-content h6 {
  font-weight: bold;
  font-size: 12px;
  line-height: 25px;
  color: rgba(255, 74, 49, 0.67);
}
.e-plan-content.e-plan-two h2, .e-plan-content.e-plan-two ul li::before {
  color: #F3B10E;
}
.e-plan-content.e-plan-three h2, .e-plan-content.e-plan-three ul li::before {
  color: #1DABF2;
}
.e-less {
  background-image: url("../images/sidebar/up-arrow.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 10px;
  height: 7px;
  position: absolute;
  cursor: pointer;
  bottom: 23px;
}
.e-plan-content a {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  color: #FF4A31;
  cursor: pointer;
}
.e-plan-content a:hover {
  color: #FF4A31;
  cursor: pointer;
}
.e-plan-wrap{
  height: 400px;
  overflow: hidden;
  margin-bottom: 30px;
  position: relative;
}
.e-plan-view-more{
  position: absolute;
  bottom: 0;
  background: #fff;
  padding: 25px 15px 15px;
  text-align: center;
  font-size: 13px;
  width: 100%;
  left: 0;
  color: #FF4A31;
  cursor: pointer;
  font-weight: 700;
}
.e-plan-view-more:hover{
  color: #000;
}
.e-plan-wrap.e-large{
  height: initial;
}




/*------------Add new Plan------------*/
.e-new-plan h2 span {
  opacity: 0.3;
}
.e-new-plan input,.e-new-plan textarea{
  /*border-bottom:transparent!important;*/
  border: 1px solid #D9D9D9;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 8px;
}
.e-new-plan textarea {
  resize: none;
}
.e-new-plan ::-webkit-input-placeholder,.e-new-plan ::-webkit-textarea-placeholder {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.25);

}
.e-new-plan .css-yk16xz-control, .css-1pahdxg-control {
  font-size: 12px;
  box-shadow: none !important;
 /* min-width: 200px!important;*/
  max-width: 300px;
}
.e-new-plan .css-1okebmr-indicatorSeparator {
  width: 0px;
}
.e-new-plan .css-g1d714-ValueContainer {
  padding: 1px 8px;
}
.e-color {
  background: #FFFFFF;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  width: 40px;
  height: 40px;
}
/*-----------------CheckBox---------------------*/

/* The container */
.e-checkbox-container {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;


}
.e-check-box label {
  line-height: 17px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.65);
}

/* Hide the browser's default checkbox */
.e-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -1px;
  left: 0;
  height: 20px;
  width: 20px;
 /* border: 1px solid #fff;*/
  background-color: #FF4A31;
  box-sizing: border-box;
  border-radius: 2px;
  background-color: #fff;
  /*background-color: #F16522;*/
}

/* On mouse-over, add a grey background color */
.e-checkbox-container:hover input ~ .checkmark {
  background-color: #fff!important;
}

/* When the checkbox is checked, add a blue background */
/* .e-checkbox-container input:checked ~ .checkmark {
  background-color: #F16522;
} */

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;

}

/* Show the checkmark when checked */
.e-checkbox-container input:checked ~ .checkmark:after{
  display: block;
}
.e-checkbox-container .e-checked ~ .checkmark:after{
  display: block;
}

/* Style the checkmark/indicator */
.e-checkbox-container .checkmark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
 /* border: solid #F16522;*/
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  /*color: #F16522;*/
}
.e-checkbox-container input:checked ~ .checkmark {
  background-color: #FF4A31!important;
}


/*toast*/
.e-error{
  background-color: #F5C0C0;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 13px;
  color: #D40000;
  font-weight: 500;
  border: 1px solid #D40000;
  text-transform: capitalize;
}
.e-toast{
  position: fixed;
  bottom: 100px;
  right: 30px;
  width: 250px;
  padding: 15px 15px 15px 40px;
  background-color: #fff;
  border: 1px solid #F1F1F1;
  border-radius: 5px;
  box-shadow: 3px 4px 6px 1px rgba(0, 0, 0, 0.06);
  font-size: 14px;
  background-size: 18px;
  background-position: 15px;
  background-repeat: no-repeat;
  z-index: 99;
}
.e-toast.success{
  background-image: url('../images/icons/check.svg');
  border-color: #68D868;
  background-color: #E9FFE0;
}
.e-toast.fail{
  background-image: url('../images/icons/cancel.svg');
  border-color: red;
  background-color: #F9E5E5;
}



/* Add Menu */
.e-menu-add-wrap .css-yk16xz-control, .e-menu-add-wrap .css-1pahdxg-control{
  max-width: 100%;
}
.e-vendor-selection .css-yk16xz-control, .e-vendor-selection .css-1pahdxg-control{
  min-width: 100%
}

/*Video Upload*/
.e-video-upload-success{
  min-height: 100px;
  padding: 36px;
  font-size: 12px;
  color: green;
  display: inline-block;
  text-align: center;
}

/*Video Player*/
.e-video-player{
  width: auto !important;
  height: 430px !important;
  text-align: center !important;
}

.e-accept{
  color: green;
  display: inline-block;
  margin-right: 15px;
  cursor: pointer;
}
.e-reject{
  color: red;
  display: inline-block;
  margin-right: 15px;
  cursor: pointer;
}
.e-accept:hover, .e-reject:hover{
  opacity: .5;
}


/*Add plan */
.e-new-plan label{
  font-size: 13px;
  font-weight: 600;
}

/*Location Admin*/
.table.e-location-admin-table a {
  color: #FF5F58;
  text-decoration: none;
  cursor: pointer;
}
.table.e-location-admin-table a:hover {
  text-decoration: underline;
}
.e-upload-layout-header{
  font-size: 14px;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}
.e-varient-wrap {
  /*height: 25px;
  width: 47px;*/
  border-radius: 10px;
  background-color: #F2F2F2;
  padding: 4px 11px;
}
.e-location-search .css-yk16xz-control, .e-location-search .css-yk16xz-control:hover, .e-location-search .css-1pahdxg-control, .e-location-search .css-1pahdxg-control:hover{
  width: 100%;
  border: none;
  box-shadow: none;
  max-width: unset;
  border-radius: 0px;
}
.e-location-search .css-yk16xz-control, .e-location-search .css-yk16xz-control:hover{
  border-bottom: 1px solid #D3D8DD;
}
.e-location-search .css-1pahdxg-control, .e-location-search .css-1pahdxg-control:hover{
  border-bottom: 1px solid #002A5C;;
}
.e-vendor-filters .css-yk16xz-control, .e-vendor-filters .css-1pahdxg-control{
  max-width: 100%;
  box-shadow: none;
}
.e-location-search .css-14jk2my-container{
  pointer-events: unset;
}
.e-location-search .css-1fhf3k1-control{
  border: none !important;
  border-radius: none !important;
  border-bottom: 1px solid #D3D8DD !important;
  background-color: #ffffff00 !important;
  cursor: not-allowed !important;
}

.e-bannerwrap {
  border: 2px solid #DBDBDB;
  padding: 10px 10px 0 10px;
  cursor: pointer;
}
.e-bannerwrap:hover {
  border: 2px solid #1468BB;
  box-shadow: 2px 2px 2px 2px rgba(206,206,206,0.5);
  transition: .4s ease;
  transform: scale(1.01);
  cursor: pointer;
}
.e-bannerwrap-active {
  border: 2px solid #1468BB;
  box-shadow: 2px 2px 2px 2px rgba(206,206,206,0.5);
  transition: .4s ease;
  transform: scale(1.01);
  font-weight: 800;
  padding: 10px 10px 0 10px;
  cursor: pointer;
}
.e-bannerwrap p {
  font-weight: bold;
}
.e-bannerstyle1 {
  width: 100%;
  height: 77px;
  object-fit: contain;
}
.e-bannerstyle2 {
  width: 84px;
  height: 111px;
  object-fit: none;
}
.e-banner-modal .modal-dialog{
  max-width: 600px;
}
.e-edit-icon{
  background-image: url("../images/icons/edit-icon.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 16px;
  height: 16px;
  opacity: 0.8;
  cursor: pointer;
  transition: opacity 0.3s ease;
}
.e-edit-icon.e-icon-small{
  width: 14px;
  height: 14px;
}
.e-edit-icon:hover{
  opacity: 1;
  filter: drop-shadow(6px 0px 18px rgba(0, 0, 0, 0.06));
}
.e-upload-wrapper, .e-megamenu-ad-wrapper{
  width: 100%;
  height: 400px;
  background: #eeea;
  transition: 0.4s ease;
}
.e-megamenu-ad{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.e-upload-wrapper:hover{
  background: #eeef;
  cursor: pointer;
}
.e-megamenu-ad-wrapper .e-close-icon{
  top: 0;
  right: 0;
  z-index: 4;
  background-color: #fffc;
  border-radius: 40px;
  width: 32px;
  height: 32px;
  background-size: 16px;
  background-repeat: no-repeat;
  transition: 0.4s ease;
}
.e-megamenu-ad-wrapper .e-close-icon:hover{
  background-color: #fff;
}

.e-cursor-pointer{
  cursor: pointer;
}
.e-category-search .e-search-input{
  border-bottom: 1px solid #90A0B760;
  width: 100%;
  position: static;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: 45px;
  transition: 0.3s ease;
	background-position: left center;
}
.e-category-search .e-search-input:focus, .e-category-search .e-search-input:active, .e-category-search .e-search-input:valid{
	width: 100%;
	border-color: #002A5C;;
	background-position: left center;
	background-image: url("../images/search-icon-secondary.svg");
}
.e-search-btn{
  width: 40px;
  height: 100%;
  top: 0;
  right: 0;
  object-fit: contain;    
  background: #002A5C;
  cursor: pointer;
  border-top-right-radius: 4px;
  background-image: url(../images/right-arrow-white.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  transition: 0.3s ease;
}
.e-search-btn:hover{
  background-size: 22px;
}
.e-popup-image-wrapper .e-upload-loader,
.e-popup-image-wrapper .e-img-upload-content img,
.e-popup-image-wrapper .e-upload-btn{
  height: 250px;
}
.e-popup-image-wrapper .e-upload-btn{
  background-position: center;
}
.e-new-col-img{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.e-new-col-label{
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  padding: 24px;
  background: #000c;
  color: #fff;
  padding: 15px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
}
.e-new-col-img-wrapper-3{
  width: 100%;
  padding-top: 100%;
}
.e-new-col-img-wrapper-3.e-new-col-wrapper-small-3{
  padding-top: calc(25% - 1rem);
}
.e-new-col-img-wrapper-4{
  width: 100%;
  padding-top: 60%;
}
.e-new-col-img-wrapper-4.e-new-col-wrapper-small-4{
  padding-top: 30%;
}
.e-new-col-img-wrapper-5{
  width: 100%;
  padding-top: 100%;
}
.e-new-col-img-wrapper-5.e-new-col-wrapper-small-5{
  padding-top: calc(25% - 1rem);
}
.e-new-col-img-wrapper-5.e-new-col-wrapper-med-5{
  padding-top: calc(50% - 1rem);
}
.e-new-col-close-icon{
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  background-color: #fffe;
  width: 32px;
  height: 32px;
  background-size: 16px;
  background-repeat: no-repeat;
}

.toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  text-align: left;
  top: px;
}
.checkbox {
  display: none;
}
.label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #bbb;
  border-radius: 20px;
}
.inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.inner:before,
.inner:after {
  float: left;
  width: 50%;
  height: 25px;
  padding: 0;
  line-height: 28px;
  color: #fff;
  font-weight: bold;
  box-sizing: border-box;
}
.inner:before {
  content: "YES";
  padding-left: 10px;
  background-color: #FF4A31;
  color: #fff;
}
.inner:after {
  content: "NO";
  padding-right: 10px;
  background-color: #bbb;
  color: #fff;
  text-align: right;
}
.switch {
  display: block;
  width:  15px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  border: 0 solid #bbb;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.checkbox:checked + .label .inner {
  margin-left: 0;
}
.checkbox:checked + .label .switch {
  right: 0px;
}
.e-copy-img:hover{
  cursor: pointer;
} 
.e-copy-text-alert{
  padding: 2px  20px;
  border-radius: 6px;;
  border: 1px solid rgb(41 185 185);
  background-color: #32e9d8;
  color: #fff;
  position: absolute;
  left: 25%;
}
.e-store-link-wrapper{
  padding: 8px 16px;
  background-color: #fff;
  box-shadow: 4px 4px 20px #0003;
  color: #000;
  font-size: 12px;
  font-weight: bold;
  width: max-content;
  border-radius: 20px;
  cursor: pointer;
  opacity: 1;
  transition: 0.3s ease-out;
}
.e-store-link-wrapper:hover{
  opacity: 0.8;
  box-shadow: 4px 4px 10px #0004;
}
.e-link-copy-icon{
  width: 14px;
  height: 14px;
  object-fit: contain;
}
.e-active-user{
  color: green;
}
.e-inactive-user{
  color: red;
}
.e-favitem-card{
  position: relative;
}
.e-favitem-card .e-close-icon{
  position: absolute;
    z-index: 10;
    background-color: white;
    width: 22px;
    height: 22px;
    background-size: 15px;
    background-repeat: no-repeat;
    right: 0;
}
.e-favitem-card img{
  height: 300px;
  object-fit: cover;
}
.e-favitem-card p{
  font-size: 14px;
  font-weight: 700;
}
.e-add-link{
  position: absolute;
  right: 20px;
}
.e-img-preview{
  border: 1px solid #D3D8DD;
  width: 28%;
  height: 108px;
  position: relative;
}
.e-img-preview img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.e-img-preview .e-upload-loader{
  height: 100%!important;
  background-position: center 24px!important;
}
.e-vendor-img{
  height: 175px;
}
.e-vendor-img-wrap{
  height: 177px;
}
.e-vendor-placeholder{
  height: 222px;
}
.e_radiobutton label::after{
  background: #ff5f58!important;
}
.e_radiobutton label::before{
  border: 1px solid #ff5f58!important;
}